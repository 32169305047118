

const Success = 0;
const ErrorAccountRegisted = 500;
const ErrorAccountFormat = 501;
const ErrorAccountNotExist = 502;
const ErrorPasswdNotMatch = 600;
const ErrorPasswdFormat = 601;
const ErrorEmailRegisted = 700;
const ErrorEmailFormat = 701;
const ErrorEmailNotExist = 702;
const ErrorEmailCodeVerify = 703;
const ErrorEmailCodeTimeout = 704;
const ErrorEmailCodeBeenSent = 705;
const ErrorEmailCodeNotExist = 706;
const ErrorEmailNotMatch = 707;
const ErrorQqRegisted = 800;
const ErrorQqFormat = 801;
const ErrorQqNotExist = 802;
const ErrorCaptchaVerify = 900;
const ErrorCaptchaTimeout = 901;
const ErrorCaptchaImageCreate = 902;
const ErrorCaptchaTokenMismatch = 903;
// const ErrorInternalSqlExecute = 1000;
// const ErrorInternalRegex = 1100;
// const ErrorInternalMail = 1200;
// const ErrorInternalEncrypt = 1300;
// const ErrorInternalDecrypt = 1301;
// const ErrorInternalHexDecrypt = 1302;
// const ErrorInternalIo = 1400;
const ErrorTokenFormat = 3000;
const ErrorTokenInvalid = 3001;
const ErrorTokenTimeout = 3002;
const ErrorMchatInsufficientFees = 5005;
const ErrorMchatInsufficientFeesGpt4o = 5012;
const ErrorMchatOpenai = 5001;
const ErrorMchatOpenAIInvalidImage = 5013;
const ErrorMchatTrialPlanRunOut = 5015;
const ErrorMchatMonthCardExpired = 5016;
const ErrorMchatMonthCardRunOut = 5017;
//const ErrorMchatMonthCardExtraRunOut = 5018;
//const ErrorMchatMonthCardNotExist = 5019;
// const ErrorInternalUnknown = 10000;


export {
    getErrorMessage
}


var getErrorMessage = function (errorCode) {
    switch (errorCode) {
        case Success :
            return "action success";
        case ErrorAccountRegisted :
            return "账号已被注册";
        case ErrorAccountFormat :
            return "账号格式错误";
        case ErrorAccountNotExist :
            return "账号不存在";

        case ErrorPasswdNotMatch :
            return "密码错误";
        case ErrorPasswdFormat :
            return "密码格式错误";

        case ErrorEmailRegisted :
            return "邮箱已被注册";
        case ErrorEmailFormat :
            return "邮箱格式错误";
        case ErrorEmailNotExist :
            return "邮箱不存在";
        case ErrorEmailCodeVerify :
            return "邮箱验证码错误";
        case ErrorEmailCodeTimeout :
            return "邮箱验证码已过期";
        case ErrorEmailCodeBeenSent :
            return "验证码已发送到您的邮箱";
        case ErrorEmailCodeNotExist :
            return "邮箱验证码不存在";
        case ErrorEmailNotMatch :
            return "邮箱不正确";

        case ErrorQqRegisted :
            return "QQ号已经注册";
        case ErrorQqFormat :
            return "QQ号格式错误";
        case ErrorQqNotExist :
            return "QQ号不存在";

        case ErrorCaptchaVerify :
            return "验证码错误";
        case ErrorCaptchaTimeout :
            return "验证码已超时";
        case ErrorCaptchaImageCreate :
            return "验证码生成错误";
        case ErrorCaptchaTokenMismatch :
            return "验证码token错误";
        case ErrorTokenFormat :
            return "页面token格式错误";
        case ErrorTokenInvalid :
            return "页面错误";
        case ErrorTokenTimeout :
            return "页面已超时";
        case ErrorMchatInsufficientFees:
        case ErrorMchatInsufficientFeesGpt4o:
            return "费用不足，请加qq群 817647474  ";
        case ErrorMchatOpenai:
            return "请求openai api出错，如果有图片，请重新拍摄或调整图片大小"
        case ErrorMchatOpenAIInvalidImage:
            return "图片无法识别，请重新拍摄或调整图片大小"
        case ErrorMchatTrialPlanRunOut:
            return "今日试用次数已不够当前模型消耗"
        case ErrorMchatMonthCardExpired:
            return "月卡已过期，且今日试用次数已耗尽"
        case ErrorMchatMonthCardRunOut:
            return "月卡次数已不够当前模型消耗"
        default:
            return "内部错误 " +  errorCode;
    }
}


