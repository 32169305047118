import axios from "axios";
import {boloApis,boloCdn} from "@/utils/Const";
import {getErrorMessage} from "@/utils/BoloError";
import {sha256} from "js-sha256";
export {
    getHeaders,
    getToken,
    requestMailCode,
    requestCaptcha,
    defaultOnError,
    query,
    insert,
    generateCdk,
    getScene,
    getLoginToken,
    saveLoginToken,
    getLoginStatus,
    sendMessages,
    generateChat,
    getMessage,
    getChatList,
    uploadImage,
    exchangeCdkey,
    wechatpayCreateOrder,
    getVipInfo,
}


function getHeaders() {
    var req = new XMLHttpRequest();
    console.log(document.location.href)
    req.open('GET', document.location.href, false);
    req.send(null);
    var headerArr = req.getAllResponseHeaders().split('\n');
    var headers = {};
    headerArr.forEach(item => {
        if (item !== '') {
            var index = item.indexOf(':');
            var key = item.slice(0, index);
            var value = item.slice(index + 1).trim();
            headers[key] = value
        }

    })
    return headers
}


function getToken(callback) {
    // we use a interface to get token for debug
    console.log(boloApis["token_get"])
    axios({
        url: boloApis["token_get"],
        method: 'get',
    }).then(res => {
        if (res.data['status'] === 0) {
            let token = res.data['token'];
            callback(token);
        }
    }).catch(error => {
        alert(error);
    });
}

function query(accountName, passwd, table, condition, line_limit, onSuccess, onError) {
    axios({
        url: boloApis["query"],
        method: 'post',
        data: JSON.stringify({
            "account_name": accountName,
            "passwd": sha256(passwd),
            "table": table,
            "query_param": condition,
            "line_limit": line_limit
        }),
        headers: {'Content-Type': 'application/json'}
    }).then(res => {
        let errorCode = res.data['status'];
        if (errorCode === 0) {
            onSuccess(res.data['data']);
        } else {
            onError(errorCode);
        }
    }).catch(error => {
        onError(error);
    });
}


function insert(accountName, passwd, table, insert_param, onSuccess, onError) {
    axios({
        url: boloApis["insert"],
        method: 'post',
        data: JSON.stringify({
            "account_name": accountName,
            "passwd": sha256(passwd),
            "table": table,
            "insert_params": insert_param,
        }),
        headers: {'Content-Type': 'application/json'}
    }).then(res => {
        let errorCode = res.data['status'];
        if (errorCode === 0) {
            onSuccess(res.data);
        } else {
            onError(errorCode);
        }
    }).catch(error => {
        onError(error);
    });
}

function generateCdk(accountName, passwd, cdkId, onSuccess, onError) {
    axios({
        url: boloApis["generate_cdkey"],
        method: 'post',
        data: JSON.stringify({
            "account_name": accountName,
            "passwd": sha256(passwd),
            "cdk_id": cdkId,
        }),
        headers: {'Content-Type': 'application/json'}
    }).then(res => {
        let errorCode = res.data['status'];
        if (errorCode === 0) {
            onSuccess();
        } else {
            onError(errorCode);
        }
    }).catch(error => {
        onError(error);
    });
}

function requestMailCode(context, mailAddress, onSuccess, onError) {
    axios({
        url: boloApis["mailcode_get"],
        method: 'post',
        data: JSON.stringify({"mail_address": mailAddress}),
        headers: {'Content-Type': 'application/json'}
    }).then(res => {
        let errorCode = res.data['status'];
        if (errorCode === 0) {
            onSuccess(context);
        } else {
            onError(context, errorCode);
        }
    }).catch(error => {
        onError(context, error);
    });
}

function defaultOnError(context, error) {
    if(error instanceof Number) {
        context.$alert(getErrorMessage(error) + "  错误代码:" + error);
    } else {
        context.$alert(error);
    }
}

function requestCaptcha(context, token, onSuccess, onError) {
    console.log("get captcha");
    axios({
        url: boloApis["captcha_get"],
        method: 'post',
        data: JSON.stringify({"token": token}),
        headers: {'Content-Type': 'application/json'}
    }).then(res => {
        let errorCode = res.data['status'];
        if (errorCode === 0) {
            onSuccess(context, res.data['image'], res.data['magic']);
        } else {
            onError(context, errorCode)
        }
    }).catch(error => {
        onError(context, error);
    });
}


function getScene(context, token, onSuccess, onError) {
    console.log("get scene");
    axios({
        url: boloApis["getscene"],
        method: 'get'
    }).then(res => {
        let errorCode = res.data['status'];
        if (errorCode === 0) {
            onSuccess(context, res.data['scene']);
        } else {
            onError(context, errorCode)
        }
    }).catch(error => {
        onError(context, error);
    });
}

function getLoginStatus(context, scene, onSuccess, onError) {
    console.log("get login status");
    axios({
        url: boloApis["getloginstatus"],
        method: 'post',
        data: JSON.stringify({"scene": scene}),
        headers: {'Content-Type': 'application/json'}
    }).then(res => {
        let errorCode = res.data['status'];
        if (errorCode === 0) {
            onSuccess(context, res.data['info']);
        } else {
            onError(context, errorCode)
        }
    }).catch(error => {
        onError(context, error);
    });
}

function sendMessages(context, data, onSuccess, onError) {
    console.log("send messages");
    axios({
        url: boloApis["sendmessage"],
        method: 'post',
        data: JSON.stringify(data),
        headers: {'Content-Type': 'application/json'},
        timeout: 120*1000
    }).then(res => {
        let errorCode = res.data['status'];
        console.log(errorCode);
        if (errorCode === 0) {
            onSuccess(context, res.data['content']);
        } else {
            if (errorCode === 5004 || errorCode === 5002) {
                expiredTokenHandler(context);
            } else {
                onError(context, errorCode);
            }
        }
    }).catch(error => {
        onError(context, error);
    });

}


function generateChat(context, data, onSuccess, onError) {
    axios({
        url: boloApis["generate_chat"],
        data: JSON.stringify(data),
        method: 'post',
        headers: {'Content-Type': 'application/json'},
        timeout: 3000,
    }).then(res => {
        let errorCode = res.data['status'];
        console.log(errorCode);
        if (errorCode === 0) {
            onSuccess(context, res.data['chat_id']);
        } else {
            if (errorCode === 5004 || errorCode === 5002) {
                expiredTokenHandler(context);
            } else {
                onError(context, errorCode)
            }
        }
    }).catch(error => {
        onError(context, error);
    });
}

function getMessage(context, data, onSuccess, onError) {
    axios({
        url: boloApis["getchatmsg"],
        data: JSON.stringify(data),
        method: 'post',
        headers: {'Content-Type': 'application/json'},
        timeout: 5000,
    }).then(res => {
        let errorCode = res.data['status'];
        if (errorCode === 0) {
            onSuccess(context, res.data['messages']);
        } else {
            if (errorCode === 5004 || errorCode === 5002) {
                expiredTokenHandler(context);
            } else {
                onError(context, errorCode)
            }
        }
    }).catch(error => {
        onError(context, error);
    });
}

function getChatList(context, data, onSuccess, onError) {
    axios({
        url: boloApis["getchatlist"],
        data: JSON.stringify(data),
        method: 'post',
        headers: {'Content-Type': 'application/json'},
        timeout: 5000,
    }).then(res => {
        let errorCode = res.data['status'];
        console.log(errorCode);
        if (errorCode === 0) {
            onSuccess(context, res.data['info']);
        } else {
            if (errorCode === 5004 || errorCode === 5002) {
                expiredTokenHandler(context);
            } else {
                onError(context, errorCode)
            }
        }
    }).catch(error => {
        onError(context, error);
    });
}


function uploadImage(context, image, fileName, verify, onSuccess, onFail) {
    let formData = new FormData();
    formData.append('file', image.raw);
    formData.append('token', verify.token);
    formData.append('uuid', verify.uuid);
    formData.append('app_id', verify.app_id);
    formData.append('file_name', fileName);
    let config = {
        headers:{'Content-Type':'multipart/form-data'}
    };

    console.log(formData);

    axios.post( boloApis['upload_image'], formData, config)
        .then(response => {
            console.log(response)
            if (response.status !== undefined && response.status !== 200) {
                onFail(response);
                return;
            }
            let data = response.data
            console.log(data)
            if (data.status === 0) {
                let url = data.url
                let real_url = boloCdn + url
                onSuccess(real_url)
            } else {
                onFail(data.status)
            }
        }).catch(error => {
        onFail(context, error);
    });
}


function exchangeCdkey(context, data, onSuccess, onError) {
    axios({
        url: boloApis["exchange_cdkey"],
        data: JSON.stringify(data),
        method: 'post',
        headers: {'Content-Type': 'application/json'},
        timeout: 10000,
    }).then(res => {
        let errorCode = res.data['status'];
        console.log(errorCode);
        if (errorCode === 0) {
            onSuccess(context);
        } else {
            onError(context, errorCode);
        }
    }).catch(error => {
        onError(context, error);
    });
}

function wechatpayCreateOrder(context, data, onSuccess, onError) {

    axios({
        url: boloApis["create_wechat_order"],
        data: JSON.stringify(data),
        method: 'post',
        headers: {'Content-Type': 'application/json'},
        timeout: 10000,
    }).then(res => {
        let errorCode = res.data['status'];
        console.log(errorCode);
        if (errorCode === 0) {
            onSuccess(context, res.data['qrcode_src']);
        } else {
            onError(context, errorCode);
        }
    }).catch(error => {
        onError(context, error);
    });
}


function getVipInfo(context, data, onSuccess, onError) {
    axios({
        url: boloApis["get_vip_info"],
        data: JSON.stringify(data),
        method: 'post',
        headers: {'Content-Type': 'application/json'},
        timeout: 10000,
    }).then(res => {
        let errorCode = res.data['status'];
        console.log(errorCode);
        if (errorCode === 0) {
            onSuccess(context, res.data['card_info']);
        } else {
            onError(context, errorCode);
        }
    }).catch(error => {
        onError(context, error);
    });
}

function expiredTokenHandler(context) {
    context.$alert("登录态已过期，请重新登录");
    context.$router.push({path: "/pages/scanlogin"});
}

function saveLoginToken(token) {
    localStorage.setItem('login_token', token);
}

// 获取token
function getLoginToken() {
    return localStorage.getItem('login_token');
}
