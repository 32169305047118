export {
    boloApis,
    boloHost,
    boloHostPort,
    boloCdn,
    boloInland
}

var boloApis;
var boloHost;
var boloHostPort;
var boloCdn;
var boloInland;
let apiPrefix = "";
boloApis = {
    "token_get": "/verify/token/get",
    "wechat_login": "/mchat/account/wechatlogin",
    "generate_chat": "/mchat/chat/generatechat",
    "getchatmsg": "/mchat/chat/getchatmsg",
    "sendmessage": "/mchat/chat/sendmsg",
    "scan_login": "/mchat/account/scanlogin",
    "getscene": "/mchat/account/get_login_scene",
    "getloginstatus": "/mchat/account/get_login_status",
    "getchatlist": "/mchat/chat/getchatlist",
    "upload_image": "/upload/upload_bolo_image",
    "exchange_cdkey": "/ms/cdkey/exchange",
    "create_wechat_order": "/order/wechatpay_create_order",
    "get_vip_info": "/mchat/account/info/month_card"
};
if (process.env.NODE_ENV === 'production') {
    boloHost = "galigulu.com";
    boloHostPort = 443;
    boloCdn = "https://cdn.api.maplebolo.com";
    boloInland = "maplebolo.com"
    apiPrefix = "https://galigulu.com/v1";
} else {
    boloHost = "127.0.0.1"
    boloHostPort = 8000;
    boloCdn = "http://127.0.0.1:8000";
    boloInland = "127.0.0.1";
    apiPrefix = "/v1"
}
for(let api in boloApis) {
    // eslint-disable-next-line no-prototype-builtins
    if (boloApis.hasOwnProperty(api)) {
        boloApis[api] = apiPrefix + boloApis[api];
    }
}


