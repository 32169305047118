import { createApp } from 'vue';
import App from './App.vue';
import {ElMenu, ElButton, ElInput, ElSelect, ElMenuItem, ElOption, ElImage,
    ElMessageBox, ElUpload, ElIcon, ElDropdown, ElDropdownMenu, ElDropdownItem,
    ElDialog, ElRadioGroup, ElRadioButton} from 'element-plus';

import VMdPreview from '@kangc/v-md-editor/lib/preview';
import '@kangc/v-md-editor/lib/style/preview.css';
// VuePress主题以及样式（这里也可以选择github主题）
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';
// import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
// import '@kangc/v-md-editor/lib/theme/style/github.css';

import Vue3SmoothScroll from "vue3-smooth-scroll";

// Prism
import Prism from 'prismjs';
//import hljs from 'highlight.js'
// 代码高亮
import 'prismjs/components/prism-json';
// 选择使用主题
 VMdPreview.use(vuepressTheme, {Prism});

import axios from 'axios'
import {getHeaders} from "@/utils/HeadTool";
import router from './router'
const app = createApp(App).use(router);
app.use(ElMenu);
app.use(ElInput);
app.use(ElSelect);
app.use(ElButton);
// app.use(ElAlert);
app.use(ElMenuItem);
app.use(ElOption);
app.use(ElImage);
app.use(ElMessageBox);
app.use(ElUpload);
app.use(ElIcon);
app.use(ElDropdown);
app.use(ElDropdownMenu);
app.use(ElDropdownItem);
app.use(ElDialog);
app.use(ElRadioGroup);
app.use(ElRadioButton);

app.use(VMdPreview);
app.use(Vue3SmoothScroll);
// app.use(installElementPlus);

app.config.globalProperties.axios = axios;
app.mount('#app');
app.config.globalProperties.headers = getHeaders();
app.config.globalProperties.login_token = "111";
app.config.globalProperties.login_scene = "";
console.log(app.config.globalProperties.headers);
